// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,700); */

body {
  margin: 0;
  /* padding: 0px 80px; */
  /* padding: 0; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  /* align-self: center; */
  background: linear-gradient(
    to right,
    rgba(92, 129, 202, 1) 0%,
    rgba(249, 140, 112, 1) 100%
  );
  background-size: 175% 175%;
  animation: bg-animation 20s ease-in-out infinite;
}
@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

/* Responsive Design */

@media (min-width: 1200px) {
}

@media (max-width: 1199px) and (min-width: 992px) {
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 767px) {
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,gFAAgF;;AAEhF;EACE,SAAS;EACT,uBAAuB;EACvB,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;EACnB,wBAAwB;EACxB;;;;GAIC;EACD,0BAA0B;EAG1B,gDAAgD;AAClD;AAwBA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA,sBAAsB;;AAEtB;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA","sourcesContent":["/* @import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,700); */\n\nbody {\n  margin: 0;\n  /* padding: 0px 80px; */\n  /* padding: 0; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  max-width: 100vw;\n  overflow-x: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  text-align: center;\n  align-items: center;\n  /* align-self: center; */\n  background: linear-gradient(\n    to right,\n    rgba(92, 129, 202, 1) 0%,\n    rgba(249, 140, 112, 1) 100%\n  );\n  background-size: 175% 175%;\n  -webkit-animation: bg-animation 20s ease-in-out infinite;\n  -moz-animation: bg-animation 20s ease-in-out infinite;\n  animation: bg-animation 20s ease-in-out infinite;\n}\n\n@-webkit-keyframes bg-animation {\n  0% {\n    background-position: 10% 0%;\n  }\n  50% {\n    background-position: 91% 100%;\n  }\n  100% {\n    background-position: 10% 0%;\n  }\n}\n@-moz-keyframes bg-animation {\n  0% {\n    background-position: 10% 0%;\n  }\n  50% {\n    background-position: 91% 100%;\n  }\n  100% {\n    background-position: 10% 0%;\n  }\n}\n@keyframes bg-animation {\n  0% {\n    background-position: 10% 0%;\n  }\n  50% {\n    background-position: 91% 100%;\n  }\n  100% {\n    background-position: 10% 0%;\n  }\n}\n\n/* Responsive Design */\n\n@media (min-width: 1200px) {\n}\n\n@media (max-width: 1199px) and (min-width: 992px) {\n}\n\n@media (max-width: 991px) and (min-width: 768px) {\n}\n\n@media (max-width: 767px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
